import * as React from "react";

import fontStyles from "../../components/BlogItem/BlogItem.module.scss";
import styles from "./AuthorHeroContainer.module.scss";

type AuthorData = {
  avatarUrl: string;
  name: string;
  description: string;
};

const AuthorHeroContainer: React.FC<AuthorData> = ({
  avatarUrl,
  name,
  description,
}) => (
  <section
    data-testid="section:AuthorHeroContainer"
    className={`full-width ${styles.container}`}
  >
    <div className={`pad-bottom--16 ${styles.authorImgContainer}`}>
      <img className={styles.authorImg} src={avatarUrl} alt={name} />
    </div>
    <h1
      className={`${fontStyles.garamond} ${styles.authorHeading} pad-bottom--24`}
      dangerouslySetInnerHTML={{ __html: name }}
    />
    <p
      className={`${fontStyles.garamond} ${styles.authorDescription}`}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  </section>
);

export default AuthorHeroContainer;
