import React from "react";
import { PageProps, graphql } from "gatsby";

import PollenTemplate from "../../layouts/pollen";
import { getRandomInt } from "../../utils/getRandomInt";
import { PollenPost } from "../../assets/Types";
import BlogHeroHome from "../../containers/PollenSectionContainer/BlogGridHome/BlogHeroHome/BlogHeroHome";
import BlogCards from "../../containers/PollenSectionContainer/BlogCards/BlogCards";
import BlogPM from "../../containers/PollenSectionContainer/BlogCards/BlogPM/BlogPM";
import AuthorHeroContainer from "../../containers/AuthorHeroContainer/AuthorHeroContainer";
import { ProductAdQuery } from "../../assets/StaticQueries/ProductAd.query";
import { YoastSEOType } from "../../assets/Types";
import SEO from "gatsby-plugin-wpgraphql-seo";

import gridStyles from "../../containers/PollenSectionContainer/BlogGridHome/BlogGridHome.module.scss";
import { deIndexSeo } from "../../utils/deIndexSeo";

type DataProps = {
  wpUser: {
    description: string;
    avatar: {
      url: string;
    };
    name: string;
    posts: {
      nodes: PollenPost[];
    };
    seo: YoastSEOType;
  };
};

const PostPage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  const { wpUser } = data;
  const {
    description,
    name,
    avatar: { url: avatarUrl },
    posts: { nodes: pollenPosts },
  } = wpUser;
  const { allWpProductAd } = ProductAdQuery();
  const productAdIndex = getRandomInt(allWpProductAd.nodes.length);
  const productAd = allWpProductAd.nodes[productAdIndex];

  return (
    <PollenTemplate isNotMainPage pathname={location.pathname}>
      {wpUser.seo && (
        <SEO post={deIndexSeo(wpUser as unknown as Queries.WpPage)} />
      )}
      <AuthorHeroContainer
        description={description}
        name={name}
        avatarUrl={avatarUrl}
      />
      <section className={`full-width ${gridStyles.blogGrid}`}>
        <div className={gridStyles.bcard}>
          {pollenPosts.length > 0 && (
            <BlogHeroHome
              extraStyles={{ opacity: 1 }}
              blogHero={pollenPosts[0]}
              hasNoBorder
            />
          )}
        </div>
        <div className={gridStyles.bside}>
          <BlogPM bside={true} productAd={productAd} />
        </div>
      </section>
      <section className={"full-width"}>
        <BlogCards posts={pollenPosts.filter((_, i) => i !== 0)} />
      </section>
    </PollenTemplate>
  );
};

export default PostPage;

export const AUTHOR_TEMPLATE_QUERY = graphql`
  query AuthorTemplateQuery($id: String!) {
    wpUser(id: { eq: $id }) {
      description
      name
      uri
      seo {
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        schema {
          articleType
          pageType
          raw
        }
      }
      avatar {
        url
      }
      posts {
        nodes {
          title
          excerpt
          uri
          author {
            node {
              name
              uri
            }
          }
          featuredImage {
            node {
              altText
              gatsbyImage(
                width: 800
                placeholder: BLURRED
                formats: [WEBP, AUTO]
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`;
