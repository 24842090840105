import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import styles from "./PostItem.module.scss";

const PostItem: React.FC<{
  post: Pick<
    Queries.WpPost,
    "author" | "featuredImage" | "title" | "uri" | "excerpt"
  >;
  extraStyles?: React.CSSProperties;
}> = ({ post, extraStyles = null }) => {
  const gatsbyImageData = post?.featuredImage?.node.gatsbyImage;

  //force
  return (
    <div className={styles.postItem}>
      <Link to={post?.uri!}>
        <GatsbyImage
          className={styles.postImage}
          image={gatsbyImageData!}
          alt={post?.featuredImage?.node.altText || ""}
        />
      </Link>
      <Link to={post?.uri!}>
        <h2 className={styles.postTitle}>{post.title}</h2>
      </Link>
      <h3
        className={styles.postExcerpt}
        dangerouslySetInnerHTML={{ __html: post?.excerpt! }}
      />
      <div className={styles.blogHeroLink}>
        <Link
          style={extraStyles!}
          to={post?.author?.node.uri!}
          dangerouslySetInnerHTML={{ __html: post?.author?.node.name! }}
        />
      </div>
    </div>
  );
};

export default PostItem;
